<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !show }">
      <div class="loader-index">
        <span></span>
      </div>
    </div>
    <!-- Loader ends-->
    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>



// import  Amplify   from 'aws-amplify';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);

export default {
  name: "app",
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      var self = this;
      setTimeout(function () {
        self.show = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(
    --amplify-colors-brand-secondary-80
  );
  --amplify-components-button-primary-hover-background-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-primary-focus-background-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-primary-active-background-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-color: var(
    --amplify-colors-brand-secondary-80
  );
  --amplify-components-button-link-hover-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-link-focus-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-link-active-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
}
</style>
