<template>
  <div class="chat-container">
    <div class="chat-header">
      <h2>Listen</h2>
    </div>
    <div class="telnyx-call">
      <button @click="closeModal">&times; Close</button>
      <button @click="toggleMute">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
    </div>
    <audio id="manager-audio" autoplay></audio>
  </div>
</template>

<script>
import io from 'socket.io-client';
import { TelnyxRTC } from '@telnyx/webrtc';
import SocketService from './socketService.js';
import axios from 'axios';

export default {
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    repId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      telnyx: null,
      currentCall: null,
      isMuted: true,
      username: 'russell32925',
      password: 'gbtKZ5ym',
      callerId: '+19492884866',
      usernameTwo: 'cmharrisiii48614',
      passwordTwo: 'T0Do3WIN',
      callerIdTwo: '+13125300209',
      telnyxRTC: null,
      localAudioTrack: null
    };
  },
  methods: {
    connect() {
      let id = this.$route.query.id;
      let managerNum = '';
      let user = '';
      let pass = '';
      let callerIdNum = '';
      if (id == '0d0a642f-3e81-47cc-917b-b5c5c70c5a48') {
        managerNum = '+19492884866';
        user = this.username;
        pass = this.password;
        callerIdNum = this.callerId;
      }
      if (id == '3a67b80-1a3f-4c8f-972f-2133ff03fb2d') {
        managerNum = '+13125300209';
        user = this.usernameTwo;
        pass = this.passwordTwo;
        callerIdNum = this.callerIdTwo;
      }
      managerNum = '+13125300209';
        user = this.usernameTwo;
        pass = this.passwordTwo;
        callerIdNum = this.callerIdTwo;
      try {
        this.telnyxRTC = new TelnyxRTC({
          login: user,
          password: pass,
          callerIdNumber: callerIdNum,
          debug: this.enableDebugging,
          logLeveL: 'debug',
        });
      } catch (err) {
        console.error("error init listening", err)
      }
      this.telnyxRTC.on('telnyx.socket.open', () => {
        console.log('Socket opened');
      });

      this.telnyxRTC.on('telnyx.socket.error', (error) => {
        console.error('Socket error', error);
      });

      this.telnyxRTC.on('telnyx.socket.close', () => {
        console.log('Socket closed');
      });

      this.telnyxRTC.on('telnyx.ready', () => {
        console.log('Telnyx ready');
      });

      this.telnyxRTC.on('telnyx.error', (error) => {
        console.error('Telnyx error', error);
      });

      this.telnyxRTC.on('telnyx.call.incoming', (call) => {
        // this.call = call;
        console.log('Incoming call', call);
      });

      this.telnyxRTC.on('telnyx.call.answered', (call) => {
        console.log('Call answered', call);
        this.attachStreamToAudioElement(this.call.remoteStream);
      });

      this.telnyxRTC.on('telnyx.call.hangup', (call) => {
        console.log('Call hung up', call);
      });

      this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
        console.log('call: ', call);
      });

      this.telnyxRTC.on('telnyx.notification', async (notification) => {
        console.log('telnyxNotification: ', notification);
        try {
          const call = notification.call;

          console.log('call: ', call.tt);

          if (notification.type === 'callUpdate' && call.state === 'ringing') {
            if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
              call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
            ) {
              // call.answer();
              // if(this.call) {
              //   this.mergeCalls(this.call, call);
              // }
            }
          }
          if (notification.type === 'callUpdate') {
          if (notification.call.state === 'active') {
            this.listeningCall = notification.call;
            console.log('tracks: ', this.listeningCall.localStream.getAudioTracks());
            this.localAudioTrack = this.listeningCall.localStream.getAudioTracks()[0];
            this.localAudioTrack.enabled = false;
            this.attachStreamToAudioElement(notification.call.remoteStream);
          }
        }
        } catch (err) {
          console.log("Error answering call: ", err);
        }
      });

      this.telnyxRTC.connect();
    },
    async toggleMute() {
      if(this.localAudioTrack) {
        this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
      }
    },
    async mergeCalls(call1, call2) {
      try {
        console.log('Merging calls:', call1, call2);
        await call1.bridge({
          call_control_id: call2.call_control_id,
          play_ringtone: false,
          park_after_unbridge: 'self'
        });
        console.log('Calls merged successfully.');
      } catch (error) {
        console.error('Error merging calls:', error);
      }
    },
    makeCall(fromNum, toNum) {
      console.log("fromNum: ", fromNum);
      console.log("toNum: ", toNum);
      if (this.telnyxRTC) {
        if (toNum.length === 10) {
          toNum = '+1' + toNum;
        }
        this.currentCall = this.telnyxRTC.newCall({
          destinationNumber: fromNum,
          callerNumber: fromNum,
        });
      }
    },
    attachStreamToAudioElement(stream) {
      const audioElement = document.getElementById('manager-audio');
      audioElement.srcObject = stream;
    },
    connectAudio() {
      if (this.currentCall) {
        this.currentCall.options.audio = true;
        this.muteCall();
      }
    },
    muteCall() {
      if (this.currentCall) {
        this.currentCall.muteAudio();
        this.isMuted = true;
      }
    },
    unmuteCall() {
      if (this.currentCall) {
        this.currentCall.unmuteAudio();
        this.isMuted = false;
      }
    },
    toggleMute() {
      if (this.isMuted) {
        this.unmuteCall();
      } else {
        this.muteCall();
      }
    },
    detachAudioElement() {
      const audioElement = document.getElementById('manager-audio');
      if (audioElement) {
        if (audioElement.srcObject) {
          // Get the tracks of the media stream
          const tracks = audioElement.srcObject.getTracks();

          // Stop each track
          tracks.forEach(track => track.stop());
        }

        audioElement.srcObject = null;  // Clear the stream
        audioElement.pause();           // Pause the audio element
      }
    },
    async closeModal() {
      try {
        await this.currentCall.hangup();
        this.detachAudioElement();
      } catch (err) {
        console.error("error hanging up listen call", err);
      }
      
      this.currentCall = null;
      this.telnyxRTC = null;
      this.$emit('modal-closed'); // Emit event when the modal is closed
      // this.$destroy();
      // this.$el.parentNode.removeChild(this.$el);
    },
  },
  mounted() {
    this.connect();
    let id = this.$route.query.id;
    axios.get(`https://123avatars.com/v2/barge-call?id=${this.repId}&managerId=${id}`)
      .then((resp) => {
        this.makeCall(this.callerId, this.phoneNumber);
      }).catch((err) => {
        console.error("error barging call: ", err);
      });
    console.log('id: ', id);
    
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.chat-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.telnyx-call {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

.close-chat-button {
  margin-top: 20px;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}
</style>