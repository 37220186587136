<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-7" style="padding: 0px">
        <div
          class="video-container"
          style="
            width: 100%;
            height: 100vh;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          "
        >
          <video width="150%" autoplay loop muted>
            <source src="../../assets/images/avatars.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <!-- <div>
              <a class="logo text-left">
                <img
                  class="img-fluid for-light"
                  src="../../assets/images/logo/logo.png"
                  alt="loginpage"
                  style="max-width: 200px"
                />
                <img
                  class="img-fluid for-dark"
                  src="../../assets/images/logo/logo_dark.png"
                  alt="loginpage"
                  style="max-width: 200px"
                />
              </a>
            </div> -->
            <div class="auth-container">
              <h2>{{ newPasswordRequired ? 'Set New Password' : forgotPassword ? 'Reset Password' : 'Login' }}</h2>
              <form @submit.prevent="handleSubmit">
                <div v-if="!newPasswordRequired">
                  <label for="email">Email:</label>
                  <input type="email" v-model="email" required />
                </div>
                <div v-if="newPasswordRequired || !forgotPassword || verificationStep">
                  <label for="password">{{ newPasswordRequired ? 'New Password' : 'Password' }}:</label>
                  <input type="password" v-model="password" required />
                </div>
                <div v-if="forgotPassword && verificationStep">
                  <label for="code">Verification Code:</label>
                  <input type="text" v-model="verificationCode" required />
                </div>
                <button type="submit">
                  {{ forgotPassword ? (verificationStep ? 'Submit New Password' : 'Send Verification Code') : newPasswordRequired ? 'Set New Password' : 'Login' }}
                </button>
                <a v-if="!forgotPassword && !newPasswordRequired" @click="forgotPassword = true">Forgot Password?</a>
              </form>
              <p v-if="errorMessage">{{ errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: "us-west-1_Xb5sM57hy", // Replace with your actual user pool id
  ClientId: "459g3alqohqvrvrln2ccla7r0v", // Replace with your actual client id
};

const userPool = new CognitoUserPool(poolData);

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      verificationCode: "",
      errorMessage: "",
      newPasswordRequired: false,
      forgotPassword: false,
      verificationStep: false,
      cognitoUser: null,
    };
  },
  methods: {
    handleSubmit() {
      this.errorMessage = '';
      if (this.forgotPassword) {
        if (this.verificationStep) {
          this.completePasswordReset();
        } else {
          this.sendVerificationCode();
        }
      } else if (this.newPasswordRequired) {
        this.completeNewPassword();
      } else {
        this.login();
      }
    },
    login() {
      const authenticationDetails = new AuthenticationDetails({
        Username: this.email,
        Password: this.password,
      });

      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log("Login successful:", result);

          // Extract the JWT token and role from the result
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();

          // Assume the role is stored as a custom attribute in the ID token
          const payload = result.getIdToken().payload;
          const userRole = payload['cognito:groups'] ? payload['cognito:groups'][0] : null; // Replace 'custom:role' with your custom attribute key

          // Save the auth data to local storage
          const userData = {
            token: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            role: userRole,
          };
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem("loggedIn", true);
            localStorage.setItem("userId", 123009);
            localStorage.setItem("username", this.email);
            localStorage.setItem("userType", 1);

          // Redirect to dashboard or another protected route
          if(!userRole) {
            //window.location = 'localhost:8080/sales';
            this.$router.push({ name: "sales" });
          }
          if(userRole == 'Managers') {
            this.$router.push({ name: "manager-dashboard" });
          }
          if(userRole == 'Admins') {
            this.$router.push({ name: "dashboard" });
          }
        },
        onFailure: (err) => {
          console.error("Error logging in:", err);
          this.errorMessage = err.message || "An error occurred during login";
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.cognitoUser = cognitoUser;
          this.newPasswordRequired = true;
          console.log("New password required:", userAttributes, requiredAttributes);
        },
      });
    },
    completeNewPassword() {
      if (!this.cognitoUser) return;

      this.cognitoUser.completeNewPasswordChallenge(this.password, {}, {
        onSuccess: (result) => {
          console.log("New password set successfully:", result);
          this.newPasswordRequired = false;
          // this.$router.push({ name: "dashboard" });
        },
        onFailure: (err) => {
          console.error("Error setting new password:", err);
          this.errorMessage = err.message || "An error occurred while setting the new password";
        },
      });
    },
    sendVerificationCode() {
      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => {
          console.log("Verification code sent successfully");
          this.verificationStep = true;
          this.errorMessage = "Verification code sent. Please check your email.";
        },
        onFailure: (err) => {
          console.error("Error requesting password reset:", err);
          this.errorMessage = err.message || "An error occurred while requesting a password reset";
        },
      });
    },
    completePasswordReset() {
      const userData = {
        Username: this.email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(this.verificationCode, this.password, {
        onSuccess: () => {
          console.log("Password reset successful");
          this.errorMessage = "Password reset successful. You can now log in with your new password.";
          this.forgotPassword = false;
          this.verificationStep = false;
        },
        onFailure: (err) => {
          console.error("Error resetting password:", err);
          this.errorMessage = err.message || "An error occurred while resetting your password";
        },
      });
    },
  },
};
</script>

<style scoped>
.auth-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.auth-container h2 {
  text-align: center;
}

.auth-container div {
  margin-bottom: 15px;
}

.auth-container label {
  display: block;
  margin-bottom: 5px;
}

.auth-container input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.auth-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-container button:hover {
  background-color: #0056b3;
}

.auth-container a {
  display: block;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.auth-container p {
  color: red;
  text-align: center;
}
</style>
