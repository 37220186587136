<template>
  <div class="container mt-5 p-4 bg-white rounded shadow">
    <h1 class="mb-4">Avatar Persona</h1>
    <div class="form-group mb-3">
      <label for="avatar-name">Avatar Name</label>
      <input type="text" class="form-control" id="avatar-name" v-model="avatarName">
      <small class="form-text text-muted">Enter the name of your avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="llm-version">LLM Version</label>
      <select class="form-control" id="llm-version" v-model="llmVersion">
        <option>GPT-4</option>
        <option>GPT-3</option>
        <option>GPT-3.5</option>
        <option>GPT-3.5 16K</option>
        <option>GPT-3.5 110K</option>
        <option>GPT-4omnichannel</option>
      </select>
      <small class="form-text text-muted">Select the language model version for the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="age">Age</label>
      <input type="number" class="form-control" id="age" v-model="age">
      <small class="form-text text-muted">Specify the age of the AI in years.</small>
    </div>
    <div class="form-group mb-3">
      <label>Gender</label>
      <div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="gender" id="male" value="male" v-model="gender">
          <label class="form-check-label" for="male">Male</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="gender" id="female" value="female" v-model="gender">
          <label class="form-check-label" for="female">Female</label>
        </div>
      </div>
      <small class="form-text text-muted">Select the gender of the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="city">City</label>
      <input type="text" class="form-control" id="city" v-model="city">
      <small class="form-text text-muted">Enter the city where the avatar is located.</small>
    </div>
    <div class="form-group mb-3">
      <label for="state">State</label>
      <select class="form-control" id="state" v-model="state">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
      <small class="form-text text-muted">Select the state where the avatar is located.</small>
    </div>
    <div class="form-group mb-3">
      <label for="role-name">Role Name</label>
      <input type="text" class="form-control" id="role-name" v-model="roleName">
      <small class="form-text text-muted">Enter the role name of the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="bio">Bio</label>
      <textarea class="form-control" id="bio" v-model="bio"></textarea>
      <button class="btn btn-primary mt-2" @click="grabFromChatGPT">Grab from ChatGPT</button>
      <small class="form-text text-muted">Write a brief biography for the avatar or grab one from ChatGPT.</small>
    </div>
    <div class="form-group mb-3">
      <label for="target-audience">Target Audience</label>
      <input type="text" class="form-control" id="target-audience" v-model="targetAudience">
      <small class="form-text text-muted">Specify the target audience for the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="outbound-caller-id">Outbound Caller ID Number</label>
      <input type="text" class="form-control" id="outbound-caller-id" v-model="outboundCallerId">
      <small class="form-text text-muted">Enter the outbound caller ID number.</small>
    </div>
    <div class="form-group mb-3">
      <label for="voicemail-callback-number">Voicemail Callback Number</label>
      <input type="text" class="form-control" id="voicemail-callback-number" v-model="voicemailCallbackNumber">
      <small class="form-text text-muted">Enter the voicemail callback number.</small>
    </div>
    <div class="form-group mb-3">
      <label>Formality</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="formality" min="1" max="10" v-model="formality">
        <span>10</span>
      </div>
      <div class="text-center">{{ formality }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Informal</span>
        <span>Very Formal</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Formality Intensity</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="formality-intensity" min="1" max="10" v-model="formalityIntensity">
        <span>10</span>
      </div>
      <div class="text-center">{{ formalityIntensity }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Low Intensity</span>
        <span>High Intensity</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Friendliness</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="friendliness" min="1" max="10" v-model="friendliness">
        <span>10</span>
      </div>
      <div class="text-center">{{ friendliness }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Least Friendly</span>
        <span>Most Friendly</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Friendliness Intensity</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="friendliness-intensity" min="1" max="10" v-model="friendlinessIntensity">
        <span>10</span>
      </div>
      <div class="text-center">{{ friendlinessIntensity }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Low Intensity</span>
        <span>High Intensity</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Professionalism</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="professionalism" min="1" max="10" v-model="professionalism">
        <span>10</span>
      </div>
      <div class="text-center">{{ professionalism }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Least Professional</span>
        <span>Most Professional</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Professionalism Intensity</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="professionalism-intensity" min="1" max="10" v-model="professionalismIntensity">
        <span>10</span>
      </div>
      <div class="text-center">{{ professionalismIntensity }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Low Intensity</span>
        <span>High Intensity</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Empathy</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="empathy" min="1" max="10" v-model="empathy">
        <span>10</span>
      </div>
      <div class="text-center">{{ empathy }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Least Empathetic</span>
        <span>Most Empathetic</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Empathy Intensity</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="empathy-intensity" min="1" max="10" v-model="empathyIntensity">
        <span>10</span>
      </div>
      <div class="text-center">{{ empathyIntensity }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Low Intensity</span>
        <span>High Intensity</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Emotion Sensitivity</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="emotion-sensitivity" min="1" max="10" v-model="emotionSensitivity">
        <span>10</span>
      </div>
      <div class="text-center">{{ emotionSensitivity }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Minimal Sensitivity</span>
        <span>High Sensitivity</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label for="specific-language">Specific Language</label>
      <select class="form-control" id="specific-language" v-model="specificLanguage">
        <option value="af">Afrikaans</option>
        <option value="sq">Albanian</option>
        <option value="am">Amharic</option>
        <option value="ar">Arabic</option>
        <option value="hy">Armenian</option>
        <option value="az">Azerbaijani</option>
        <option value="eu">Basque</option>
        <option value="be">Belarusian</option>
        <option value="bn">Bengali</option>
        <option value="bs">Bosnian</option>
        <option value="bg">Bulgarian</option>
        <option value="ca">Catalan</option>
        <option value="ceb">Cebuano</option>
        <option value="ny">Chichewa</option>
        <option value="zh">Chinese (Simplified)</option>
        <option value="zh-TW">Chinese (Traditional)</option>
        <option value="co">Corsican</option>
        <option value="hr">Croatian</option>
        <option value="cs">Czech</option>
        <option value="da">Danish</option>
        <option value="nl">Dutch</option>
        <option value="en">English</option>
        <option value="eo">Esperanto</option>
        <option value="et">Estonian</option>
        <option value="tl">Filipino</option>
        <option value="fi">Finnish</option>
        <option value="fr">French</option>
        <option value="fy">Frisian</option>
        <option value="gl">Galician</option>
        <option value="ka">Georgian</option>
        <option value="de">German</option>
        <option value="el">Greek</option>
        <option value="gu">Gujarati</option>
        <option value="ht">Haitian Creole</option>
        <option value="ha">Hausa</option>
        <option value="haw">Hawaiian</option>
        <option value="he">Hebrew</option>
        <option value="hi">Hindi</option>
        <option value="hmn">Hmong</option>
        <option value="hu">Hungarian</option>
        <option value="is">Icelandic</option>
        <option value="ig">Igbo</option>
        <option value="id">Indonesian</option>
        <option value="ga">Irish</option>
        <option value="it">Italian</option>
        <option value="ja">Japanese</option>
        <option value="jw">Javanese</option>
        <option value="kn">Kannada</option>
        <option value="kk">Kazakh</option>
        <option value="km">Khmer</option>
        <option value="rw">Kinyarwanda</option>
        <option value="ko">Korean</option>
        <option value="ku">Kurdish (Kurmanji)</option>
        <option value="ky">Kyrgyz</option>
        <option value="lo">Lao</option>
        <option value="la">Latin</option>
        <option value="lv">Latvian</option>
        <option value="lt">Lithuanian</option>
        <option value="lb">Luxembourgish</option>
        <option value="mk">Macedonian</option>
        <option value="mg">Malagasy</option>
        <option value="ms">Malay</option>
        <option value="ml">Malayalam</option>
        <option value="mt">Maltese</option>
        <option value="mi">Maori</option>
        <option value="mr">Marathi</option>
        <option value="mn">Mongolian</option>
        <option value="my">Myanmar (Burmese)</option>
        <option value="ne">Nepali</option>
        <option value="no">Norwegian</option>
        <option value="or">Odia (Oriya)</option>
        <option value="ps">Pashto</option>
        <option value="fa">Persian</option>
        <option value="pl">Polish</option>
        <option value="pt">Portuguese</option>
        <option value="pa">Punjabi</option>
        <option value="ro">Romanian</option>
        <option value="ru">Russian</option>
        <option value="sm">Samoan</option>
        <option value="gd">Scots Gaelic</option>
        <option value="sr">Serbian</option>
        <option value="st">Sesotho</option>
        <option value="sn">Shona</option>
        <option value="sd">Sindhi</option>
        <option value="si">Sinhala</option>
        <option value="sk">Slovak</option>
        <option value="sl">Slovenian</option>
        <option value="so">Somali</option>
        <option value="es">Spanish</option>
        <option value="su">Sundanese</option>
        <option value="sw">Swahili</option>
        <option value="sv">Swedish</option>
        <option value="tg">Tajik</option>
        <option value="ta">Tamil</option>
        <option value="tt">Tatar</option>
        <option value="te">Telugu</option>
        <option value="th">Thai</option>
        <option value="tr">Turkish</option>
        <option value="tk">Turkmen</option>
        <option value="uk">Ukrainian</option>
        <option value="ur">Urdu</option>
        <option value="ug">Uyghur</option>
        <option value="uz">Uzbek</option>
        <option value="vi">Vietnamese</option>
        <option value="cy">Welsh</option>
        <option value="xh">Xhosa</option>
        <option value="yi">Yiddish</option>
        <option value="yo">Yoruba</option>
        <option value="zu">Zulu</option>
      </select>
      <small class="form-text text-muted">Select the language the avatar should use.</small>
    </div>
    <div class="form-group mb-3">
      <label>Localization Level</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="localization-level" min="1" max="10" v-model="localizationLevel">
        <span>10</span>
      </div>
      <div class="text-center">{{ localizationLevel }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Standard Language Use</span>
        <span>Highly Localized</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Memory Past Interactions</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="memory-past-interactions" min="1" max="10" v-model="memoryPastInteractions">
        <span>10</span>
      </div>
      <div class="text-center">{{ memoryPastInteractions }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Generic Responses</span>
        <span>Highly Contextual</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label for="end-of-call-actions">End-of-Call Actions</label>
      <input type="text" class="form-control" id="end-of-call-actions" v-model="endOfCallActions">
      <small class="form-text text-muted">Script how the call should be concluded, including summarizing the conversation, confirming next steps, or thanking the caller.</small>
    </div>
    <div class="form-group mb-3">
      <label>Voice Tone</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="voice-tone" min="1" max="10" v-model="voiceTone">
        <span>10</span>
      </div>
      <div class="text-center">{{ voiceTone }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Soft Tone</span>
        <span>Strong Tone</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Speed of Speech</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="speed-of-speech" min="1" max="10" v-model="speedOfSpeech">
        <span>10</span>
      </div>
      <div class="text-center">{{ speedOfSpeech }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Slow</span>
        <span>Fast</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label for="accent">Accent</label>
      <select class="form-control" id="accent" v-model="accent">
        <option value="american">American</option>
        <option value="british">British</option>
        <option value="australian">Australian</option>
        <option value="canadian">Canadian</option>
        <option value="indian">Indian</option>
      </select>
      <small class="form-text text-muted">Select the accent for the AI's voice.</small>
    </div>
    <div class="form-group mb-3">
      <label>Personality Traits</label>
      <div v-for="trait in personalityTraits" :key="trait.code" class="mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" :id="trait.code" :value="trait.name" v-model="trait.selected">
          <label class="form-check-label" :for="trait.code">{{ trait.name }}</label>
        </div>
      </div>
      <small class="form-text text-muted">Select the personality traits for the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label for="preferred-topics">Preferred Topics</label>
      <input type="text" class="form-control" id="preferred-topics" v-model="preferredTopics">
      <small class="form-text text-muted">List topics the AI is knowledgeable or enthusiastic about.</small>
    </div>
    <div class="form-group mb-3">
      <label for="interaction-style">Interaction Style</label>
      <select class="form-control" id="interaction-style" v-model="interactionStyle">
        <option>Conversational</option>
        <option>Informative</option>
        <option>Advisory</option>
      </select>
      <small class="form-text text-muted">Choose the interaction style for the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label>Response Length</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="response-length" min="1" max="10" v-model="responseLength">
        <span>10</span>
      </div>
      <div class="text-center">{{ responseLength }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Short</span>
        <span>Long</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Positivity Level</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="positivity-level" min="1" max="10" v-model="positivityLevel">
        <span>10</span>
      </div>
      <div class="text-center">{{ positivityLevel }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Neutral</span>
        <span>Very Positive</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Question Asking Frequency</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="question-asking-frequency" min="1" max="10" v-model="questionAskingFrequency">
        <span>10</span>
      </div>
      <div class="text-center">{{ questionAskingFrequency }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Rarely</span>
        <span>Frequently</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label for="knowledge-scope">Knowledge Scope</label>
      <select class="form-control" id="knowledge-scope" v-model="knowledgeScope">
        <option>General</option>
        <option>Technical</option>
        <option>Specialized</option>
      </select>
      <small class="form-text text-muted">Select the scope of knowledge for the avatar.</small>
    </div>
    <div class="form-group mb-3">
      <label>Interruption Tolerance</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="interruption-tolerance" min="1" max="10" v-model="interruptionTolerance">
        <span>10</span>
      </div>
      <div class="text-center">{{ interruptionTolerance }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Low</span>
        <span>High</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label for="cultural-awareness">Cultural Awareness</label>
      <select class="form-control" id="cultural-awareness" v-model="culturalAwareness">
        <option value="very-low">Very Low</option>
        <option value="low">Low</option>
        <option value="moderate">Moderate</option>
        <option value="high">High</option>
        <option value="very-high">Very High</option>
      </select>
      <small class="form-text text-muted">Select the level of cultural awareness and sensitivity for the avatar.</small>
    </div>
    <div class="form-group form-check mb-3">
      <input type="checkbox" class="form-check-input" id="background-noise-filter" v-model="backgroundNoiseFilter">
      <label class="form-check-label" for="background-noise-filter">Background Noise Filter</label>
      <small class="form-text text-muted">Enable or disable background noise filtering.</small>
    </div>
    <div class="form-group mb-3">
      <label>User Feedback Incorporation</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="user-feedback-incorporation" min="1" max="10" v-model="userFeedbackIncorporation">
        <span>10</span>
      </div>
      <div class="text-center">{{ userFeedbackIncorporation }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Minimal</span>
        <span>High</span>
      </small>
    </div>
    <div class="form-group form-check mb-3">
      <input type="checkbox" class="form-check-input" id="adaptive-learning" v-model="adaptiveLearning">
      <label class="form-check-label" for="adaptive-learning">Adaptive Learning</label>
      <small class="form-text text-muted">Enable the AI to adapt responses based on ongoing interactions.</small>
    </div>
    <div class="form-group mb-3">
      <label>Humor Level</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="humor-level" min="1" max="10" v-model="humorLevel">
        <span>10</span>
      </div>
      <div class="text-center">{{ humorLevel }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Serious</span>
        <span>Humorous</span>
      </small>
    </div>
    <div class="form-group mb-3">
      <label>Pause Between Responses</label>
      <div class="d-flex justify-content-between align-items-center">
        <span>1</span>
        <input type="range" class="form-range" id="pause-between-responses" min="1" max="10" v-model="pauseBetweenResponses">
        <span>10</span>
      </div>
      <div class="text-center">{{ pauseBetweenResponses }}</div>
      <small class="form-text text-muted d-flex justify-content-between">
        <span>Short</span>
        <span>Long</span>
      </small>
    </div>
    <div class="form-group">
      <button type="button" class="btn btn-primary" @click="saveAvatar">Save</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      personaId: null,
      avatarName: '',
      llmVersion: 'GPT-4',
      age: null,
      gender: '',
      city: '',
      state: 'CA',
      roleName: '',
      bio: '',
      targetAudience: '',
      outboundCallerId: '',
      voicemailCallbackNumber: '',
      formality: 5,
      formalityIntensity: 5,
      friendliness: 5,
      friendlinessIntensity: 5,
      professionalism: 5,
      professionalismIntensity: 5,
      empathy: 5,
      empathyIntensity: 5,
      emotionSensitivity: 5,
      specificLanguage: 'en',
      localizationLevel: 5,
      memoryPastInteractions: 5,
      endOfCallActions: '',
      voiceTone: 5,
      speedOfSpeech: 5,
      accent: 'american',
      personalityTraits: [
        { code: 0, name: 'Friendly', selected: false },
        { code: 1, name: 'Assertive', selected: false },
        { code: 2, name: 'Empathetic', selected: false },
        { code: 3, name: 'Sympathetic', selected: false },
        { code: 4, name: 'Analytical', selected: false },
        { code: 5, name: 'Organized', selected: false },
        { code: 6, name: 'Charismatic', selected: false },
        { code: 7, name: 'Confident', selected: false },
        { code: 8, name: 'Patient', selected: false },
        { code: 9, name: 'Passionate', selected: false },
        { code: 10, name: 'Decisive', selected: false },
        { code: 11, name: 'Trustworthy', selected: false },
        { code: 12, name: 'Persuasive', selected: false },
        { code: 13, name: 'Positive', selected: false },
        { code: 14, name: 'Calm', selected: false },
        { code: 15, name: 'Reliable', selected: false },
        { code: 16, name: 'Resourceful', selected: false }
      ],
      preferredTopics: '',
      interactionStyle: 'Conversational',
      responseLength: 5,
      positivityLevel: 5,
      questionAskingFrequency: 5,
      knowledgeScope: 'General',
      interruptionTolerance: 5,
      culturalAwareness: '',
      backgroundNoiseFilter: false,
      userFeedbackIncorporation: 5,
      adaptiveLearning: false,
      humorLevel: 5,
      pauseBetweenResponses: 5,
    };
  },
  created() {
    this.fetchAvatarData();
  },
  methods: {
    grabFromChatGPT() {
      let text = `New avatar bio attributes: Name: ${this.avatarName}, Age: ${this.age}, Gender: ${this.gender}, Location: ${this.city}, ${this.state}, Target Audience: ${this.targetAudience}, Role Name: ${this.roleName}.`;
      axios.get(`https://123avatars.com/backend/generateBio.php?id=&text=${text}`)
        .then(response => {
          this.bio = response.data;
        })
        .catch(error => {
          console.error('Error generating bio from ChatGPT:', error);
        });
    },
    fetchAvatarData() {
      axios.get(`https://123avatars.com/v2/avatar-persona?userId=${localStorage.getItem('userId')}`)
        .then(response => {
          this.personaId = response.data.id;
          const data = JSON.parse(response.data.persona);
          this.avatarName = data.avatarName;
          this.llmVersion = data.llmVersion;
          this.age = data.age;
          this.gender = data.gender;
          this.city = data.city;
          this.state = data.state;
          this.roleName = data.roleName;
          this.bio = data.bio;
          this.targetAudience = data.targetAudience;
          this.outboundCallerId = data.outboundCallerId;
          this.voicemailCallbackNumber = data.voicemailCallbackNumber;
          this.formality = data.formality;
          this.formalityIntensity = data.formalityIntensity;
          this.friendliness = data.friendliness;
          this.friendlinessIntensity = data.friendlinessIntensity;
          this.professionalism = data.professionalism;
          this.professionalismIntensity = data.professionalismIntensity;
          this.empathy = data.empathy;
          this.empathyIntensity = data.empathyIntensity;
          this.emotionSensitivity = data.emotionSensitivity;
          this.specificLanguage = data.specificLanguage;
          this.localizationLevel = data.localizationLevel;
          this.memoryPastInteractions = data.memoryPastInteractions;
          this.endOfCallActions = data.endOfCallActions;
          this.voiceTone = data.voiceTone;
          this.speedOfSpeech = data.speedOfSpeech;
          this.accent = data.accent;
          this.personalityTraits = data.personalityTraits;
          this.preferredTopics = data.preferredTopics;
          this.interactionStyle = data.interactionStyle;
          this.responseLength = data.responseLength;
          this.positivityLevel = data.positivityLevel;
          this.questionAskingFrequency = data.questionAskingFrequency;
          this.knowledgeScope = data.knowledgeScope;
          this.interruptionTolerance = data.interruptionTolerance;
          this.culturalAwareness = data.culturalAwareness;
          this.backgroundNoiseFilter = data.backgroundNoiseFilter;
          this.userFeedbackIncorporation = data.userFeedbackIncorporation;
          this.adaptiveLearning = data.adaptiveLearning;
          this.humorLevel = data.humorLevel;
          this.pauseBetweenResponses = data.pauseBetweenResponses;
        })
        .catch(error => {
          console.error('Error fetching avatar data:', error);
        });
    },
    saveAvatar() {
      const avatarData = {
        userId: localStorage.getItem("userId"),
        avatarName: this.avatarName,
        llmVersion: this.llmVersion,
        age: this.age,
        gender: this.gender,
        city: this.city,
        state: this.state,
        roleName: this.roleName,
        bio: this.bio,
        targetAudience: this.targetAudience,
        outboundCallerId: this.outboundCallerId,
        voicemailCallbackNumber: this.voicemailCallbackNumber,
        formality: this.formality,
        formalityIntensity: this.formalityIntensity,
        friendliness: this.friendliness,
        friendlinessIntensity: this.friendlinessIntensity,
        professionalism: this.professionalism,
        professionalismIntensity: this.professionalismIntensity,
        empathy: this.empathy,
        empathyIntensity: this.empathyIntensity,
        emotionSensitivity: this.emotionSensitivity,
        specificLanguage: this.specificLanguage,
        localizationLevel: this.localizationLevel,
        memoryPastInteractions: this.memoryPastInteractions,
        endOfCallActions: this.endOfCallActions,
        voiceTone: this.voiceTone,
        speedOfSpeech: this.speedOfSpeech,
        accent: this.accent,
        personalityTraits: this.personalityTraits,
        preferredTopics: this.preferredTopics,
        interactionStyle: this.interactionStyle,
        responseLength: this.responseLength,
        positivityLevel: this.positivityLevel,
        questionAskingFrequency: this.questionAskingFrequency,
        knowledgeScope: this.knowledgeScope,
        interruptionTolerance: this.interruptionTolerance,
        culturalAwareness: this.culturalAwareness,
        backgroundNoiseFilter: this.backgroundNoiseFilter,
        userFeedbackIncorporation: this.userFeedbackIncorporation,
        adaptiveLearning: this.adaptiveLearning,
        humorLevel: this.humorLevel,
        pauseBetweenResponses: this.pauseBetweenResponses,
      };
      let data = {
        userId: localStorage.getItem('userId'),
        personaId: this.personaId,
        persona: JSON.stringify(avatarData)
      };
      axios.post('https://123avatars.com/v2/avatar-persona', data)
        .then(response => {
          console.log('Avatar data saved successfully:', response.data);
          this.$toasted.show("Avatar Persona saved successfully.", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "info",
            duration: 10000,
          });
        })
        .catch(error => {
          console.error('Error saving avatar data:', error);
          this.$toasted.show("Failed to save avatar persona.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
        });
    }
  }
};
</script>

<style scoped>
/* Additional custom styles if needed */
</style>