<template>
    <div>
      <!-- Trigger/Open The Modal -->
      <!-- <button @click="openModal" class="btn btn-primary">Setup Callback</button> -->
  
      <!-- The Modal -->
      <div v-if="true" class="modal d-flex align-items-center justify-content-center">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Setup Callback</h5>
            <button type="button" class="close" @click="closeModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body d-flex justify-content-between">
            <div class="prospect-info">
              <p><strong>Owner's Name:</strong> {{ prospect.owner }}</p>
              <p><strong>Company Name:</strong> {{ prospect.company_name }}</p>
              <p><strong>Timezone:</strong> {{ prospect.timezone }}</p>
              <p><strong>Local Time:</strong> {{ getLocalTimeFromAbbreviation(prospect.timezone) }}</p>
            </div>
            <div class="callback-info">
              <div class="form-group">
                <label for="callbackTime">Callback Date and Time ({{ prospect.timezone }})</label>
                <input type="datetime-local" v-model="callbackTime" id="callbackTime" class="form-control" @change="calculateTimeDifference">
              </div>
              <p><strong>Your Time:</strong> {{ userTime }}</p>
              <p><strong>Time Difference:</strong> {{ timeDifference }} hours</p>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="closeModal">Cancel</button>
            <button class="btn btn-success" @click="checkAndSetCallback">Set Callback</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
  import 'moment-timezone/data/packed/latest.json';
  
  export default {
    props: {
      prospect: {
        type: Object,
        required: true,
        default: () => ({
          owner: '',
          company_name: '',
          timezone: '',
          localTime: '',
          leadId: ''
        })
      }
    },
    data() {
      return {
        showModal: false,
        callbackTime: '',
        userTime: '',
        timeDifference: '',
        days: {
          0: "Sunday",
          1: "Monday",
          2: "Tuesday",
          3: "Wednesday",
          4: "Thursday",
          5: "Friday",
          6: "Saturday"
        }
      };
    },
    methods: {
      openModal() {
 //       this.showModal = true;
      },
      closeModal() {
        this.$emit('callbacks-closed'); 
   //     this.showModal = false;
      },
      getLocalTimeFromAbbreviation(abbreviation) {
        // Map of timezone abbreviations to IANA timezone names
        const timezones = {
          'EST': 'America/New_York',        // Eastern Standard Time
          'EDT': 'America/New_York',        // Eastern Daylight Time (same as EST for IANA purposes)
          'CST': 'America/Chicago',         // Central Standard Time
          'CDT': 'America/Chicago',         // Central Daylight Time (same as CST for IANA purposes)
          'MST': 'America/Denver',          // Mountain Standard Time
          'MDT': 'America/Denver',          // Mountain Daylight Time (same as MST for IANA purposes)
          'PST': 'America/Los_Angeles',     // Pacific Standard Time
          'PDT': 'America/Los_Angeles',     // Pacific Daylight Time (same as PST for IANA purposes)
          'AKST': 'America/Anchorage',      // Alaska Standard Time
          'AKDT': 'America/Anchorage',      // Alaska Daylight Time (same as AKST for IANA purposes)
          'HST': 'Pacific/Honolulu',        // Hawaii Standard Time (Hawaii does not observe Daylight Saving Time)
          'HAST': 'Pacific/Honolulu',       // Hawaii-Aleutian Standard Time (same as HST)
          'HADT': 'America/Adak',           // Hawaii-Aleutian Daylight Time
          'AST': 'America/Puerto_Rico',     // Atlantic Standard Time (used in Puerto Rico and the US Virgin Islands)
          'ADT': 'America/Halifax',         // Atlantic Daylight Time (not widely used in the U.S.)
          'CHST': 'Pacific/Guam',           // Chamorro Standard Time (Guam)
          'SST': 'Pacific/Pago_Pago',       // Samoa Standard Time (American Samoa)
        };

        // Get the current date and time
        const now = new Date();

        // Get the IANA timezone name from the abbreviation
        const timeZone = timezones[abbreviation];

        // If the timezone abbreviation is not found, return null
        if (!timeZone) {
          console.error('Timezone abbreviation not found');
          return null;
        }

        // Format the date and time according to the timezone
        const options = {
          timeZone,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat([], options);
        return formatter.format(now);
      },
      calculateTimeDifference() {
        console.log('callbackTime: ', this.callbackTime);

        // Parse the callbackTime (assumed to be in the prospect's timezone)
        const selectedTime = new Date(this.callbackTime);
        const prospectUTCOffset = selectedTime.getTimezoneOffset() / 60;

        // Get the prospect's timezone offset (in hours) from the predefined object
        const timezoneOffsets = {
          PST: -8,
          PDT: -7,
          MST: -7,
          MDT: -6,
          CST: -6,
          CDT: -5,
          EST: -5,
          EDT: -4,
          HST: -10,
          HDT: -9,
          AKST: -9,
          AKDT: -8 
        };

        // Get the offset for the prospect's timezone
        const prospectOffset = timezoneOffsets[this.timezone] || 0;
        const overallOffset = prospectUTCOffset - prospectOffset;

        // Convert the selected time to UTC
        const utcTime = new Date(selectedTime.getTime() - (overallOffset * 60 * 60 * 1000));
        console.log('utcTime: ', utcTime);

        // Get the local timezone offset in hours
        const localOffset = -utcTime.getTimezoneOffset() / 60;

        // Convert UTC time to the browser's local time
        const localTime = new Date(utcTime.toLocaleString('en-US', { timeZone: 'UTC' }));
        //const localTime = new Date();
        // Store the local time for display
        this.userTime = localTime.toLocaleString();
        // prospect time
        const prospectTime = this.getLocalTimeFromAbbreviation(this.prospect.timezone);
        // Calculate the time difference in hours between the prospect's time and local time
        const timeDifference = Math.abs((new Date().getHours() - new Date(prospectTime).getHours()));
        this.timeDifference = Math.abs(timeDifference);

        console.log('Converted Local Time: ', this.userTime);
        console.log('Time Difference (in hours): ', this.timeDifference);
      },
      getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    async checkAndSetCallback() {
      try {
          // Get the day of the week for the callback time
          const callbackTime = new Date(this.callbackTime);
          const options = { weekday: 'long' };
          const callbackDayName = callbackTime.toLocaleDateString('en-US', options);

          // Make the request with the callback day name
          const response = await axios.get('https://123avatars.com/v2/schedule', {
              params: {
                  userId: '123009',
                  type: 'Callbacks',
                  day: callbackDayName // Use the actual day of the callback here
              }
          });

          const schedule = response.data;
          const userTimezone = this.prospect.timezone;

          // Create date objects for open and close times based on the callback date
          const openTime = new Date(callbackTime);
          const [openHour, openMinute] = schedule.openTime.split(':');
          openTime.setUTCHours(openHour, openMinute, 0, 0);

          const closeTime = new Date(callbackTime);
          const [closeHour, closeMinute] = schedule.closeTime.split(':');
          closeTime.setUTCHours(closeHour, closeMinute, 0, 0);

          // Adjust for day changes if close time is earlier than open time (spanning midnight)
          if (closeTime.getUTCHours() < openTime.getUTCHours()) {
              closeTime.setUTCDate(closeTime.getUTCDate() + 1);
          }

          // Convert openTime and closeTime to the user's timezone
          const openTimeInUserTz = new Date(openTime.toLocaleString('en-US', { timeZone: userTimezone }));
          const closeTimeInUserTz = new Date(closeTime.toLocaleString('en-US', { timeZone: userTimezone }));

          console.log('openTime: ', openTimeInUserTz);
          console.log('closeTime: ', closeTimeInUserTz);
          console.log('callbackTime: ', callbackTime);

          // Check if the callback time falls within the open and close times
          if (callbackTime >= openTimeInUserTz && callbackTime <= closeTimeInUserTz) {
              await this.setCallback();
          } else {
              alert('The selected callback time is outside the available schedule.');
          }
      } catch (error) {
          console.error('Error checking the schedule or setting the callback:', error);
      }
  },
    getCurrentDayName() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = new Date();
      return days[today.getDay()];
    },
    convertToUTC(callbackTime, timezone) {
      // Timezone mappings
      const timezoneMapping = {
          'EST': 'America/New_York',
          'EDT': 'America/New_York',
          'CST': 'America/Chicago',
          'CDT': 'America/Chicago',
          'MST': 'America/Denver',
          'MDT': 'America/Denver',
          'PST': 'America/Los_Angeles',
          'PDT': 'America/Los_Angeles',
          'AKST': 'America/Anchorage',
          'AKDT': 'America/Anchorage',
          'HST': 'Pacific/Honolulu',
          'HDT': 'Pacific/Honolulu' // Hawaii doesn't observe DST, but included for consistency
      };

      const fullTimezone = timezoneMapping[timezone];

      if (!fullTimezone) {
          throw new Error(`Invalid timezone: ${timezone}`);
      }

      // Parse the date in the provided timezone
      const options = { timeZone: fullTimezone, hour12: false };
      const parts = new Intl.DateTimeFormat('en-US', {
          ...options,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
      }).formatToParts(new Date(callbackTime));

      // Construct the date string
      const dateStr = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;

      console.log('dateString: ', dateStr);
      // Create a Date object in the correct timezone
      const localDate = new Date(dateStr);

      // Convert to UTC
      const utcDate = new Date(localDate.toLocaleString('en-US', { timeZone: 'UTC' }));

      return utcDate.toISOString();
  },
    async setCallback() {
      try {
          // Convert the callbackTime from the prospect's timezone to a Date object
          const callbackDate = new Date();

          // Convert the callback time to UTC
          const utcCallbackTime = this.convertToUTC(this.callbackTime, this.prospect.timezone); //new Date(callbackDate.toLocaleString('en-US', { timeZone: 'UTC' }));

          console.log('callbackDate: ', callbackDate);
          console.log('utcCallbackTime: ', utcCallbackTime);

          // Convert the callback time to the browser's local timezone
          const userTime = new Date(callbackDate.toLocaleString());

          // Prepare the data to send to the server
          const callbackData = {
              ownerName: this.prospect.owner,
              companyName: this.prospect.company_name,
              callbackTime: this.callbackTime, // Send the time in UTC format
              sales_rep_id: this.getCognitoSub(),
              leadId: this.prospect.leadId,
              timezone: this.prospect.timezone,
              userTime: userTime.toISOString() // Send the user time in the browser's timezone
          };

          // Make the POST request
          const response = await axios.post('https://123avatars.com/v2/setCallback', callbackData);

          alert('Callback set successfully');
          this.closeModal();
      } catch (error) {
          console.error('There was an error setting the callback:', error);
      }
  }

    }
  };
  </script>
  
  <style scoped>
  .modal {
    display: flex;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  .modal-header,
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
  }
  .modal-footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 10px;
  }
  .modal-body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .prospect-info,
  .callback-info {
    width: 45%;
  }
  </style>