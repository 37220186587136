<template>
    <div style="width:80%; margin-left:10%; margin-top:5%;">
      <div class="card-header bg-dark text-white">
        <h1>Agents Management</h1>
      </div>
  
      <div class="card-body">
        <h2>Active Agents</h2>
        <div class="d-flex justify-content-between my-3">
          <input v-model="searchQuery" type="text" class="form-control" placeholder="Search active agents by name, email, or phone...">
          <button @click="showModal = true" class="btn btn-primary">Add New User</button>
        </div>
        <!-- <div class="form-group">
          <select v-model="filterManager" class="form-control">
            <option value="">All Managers</option>
            <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
          </select>
        </div> -->
        <div class="card mt-3">
          <div class="card-body">
            <div v-for="manager in filteredManagers" :key="manager.id" class="mb-4">
              <div class="d-flex justify-content-between">
                <h3 class="text-danger">{{ manager.name }}</h3>
                <button v-if="manager.agents.length === 0" @click="removeManager(manager.id)" class="btn btn-link text-danger">Remove</button>
              </div>
              <table class="table table-bordered mt-3">
                <thead class="thead-light">
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email/Username</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <!-- <th>Manager</th> -->
                    <th>Hire Date</th>
                    <th>Shelf Life</th>
                    <th>Age</th>
                    <th>Timezone</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="rep in manager.agents" :key="rep.id">
                    <td><input v-model="rep.firstName" type="text" class="form-control"></td>
                    <td><input v-model="rep.lastName" type="text" class="form-control"></td>
                    <td><input v-model="rep.email" type="email" class="form-control" readonly></td>
                    <td><input v-model="rep.phone" type="tel" class="form-control" @input="formatPhone(rep)"></td>
                    <td>{{ rep.role }}</td>
                    <!-- <td>
                      <select v-model="rep.managerId" class="form-control">
                        <option value="">None</option>
                        <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
                      </select>
                    </td> -->
                    <td>{{ formatDate(rep.createdAt) }}</td>
                    <td>{{ calculateShelfLife(rep.createdAt) }} days</td>
                    <td>{{ calculateAge(rep.dob) }}</td>
                    <td>{{ rep.timezone }}</td>
                    <td>
                      <button @click="removeUser(rep.id)" class="btn btn-link text-danger">Remove</button>
                      <!-- <button @click="openResetPasswordModal(rep)" class="btn btn-link text-primary">Reset Password</button> -->
                      <button @click="openSetDOBModal(rep)" class="btn btn-link text-success">Set DOB</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Add New User Modal -->
      <b-modal v-model="showModal" title="Add New User" hide-footer>
        <form @submit.prevent="createUser">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input v-model="newUser.firstName" type="text" id="firstName" class="form-control">
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input v-model="newUser.lastName" type="text" id="lastName" class="form-control">
          </div>
          <div class="form-group">
            <label for="email">Email/Username</label>
            <input v-model="newUser.email" type="email" id="email" class="form-control">
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input v-model="newUser.phone" type="tel" id="phone" class="form-control" @input="formatPhone(newUser)">
          </div>
          <!-- <div class="form-group" v-if="newUser.role === 'Agent'">
            <label for="manager">Manager</label>
            <select v-model="newUser.managerId" id="manager" class="form-control">
              <option value="">None</option>
              <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
            </select>
          </div> -->
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input v-model="newUser.dob" type="date" id="dob" class="form-control">
          </div>
          <div class="form-group">
            <label for="role">Role</label>
            <select v-model="newUser.role" id="role" class="form-control">
              <option value="Agent">Agent</option>
              <!-- <option value="Manager">Manager</option> -->
            </select>
          </div>
          <b-button type="submit" variant="primary">Create User</b-button>
          <b-button @click="showModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        </form>
      </b-modal>
  
      <!-- Reset Password Modal -->
      <b-modal v-model="showResetPasswordModal" title="Reset Password">
        <form @submit.prevent="resetPassword">
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input v-model="passwordForm.newPassword" type="password" id="newPassword" class="form-control">
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input v-model="passwordForm.confirmPassword" type="password" id="confirmPassword" class="form-control">
          </div>
          <div v-if="passwordError" class="alert alert-danger">
            {{ passwordError }}
          </div>
          <b-button type="submit" variant="primary">Save</b-button>
          <b-button @click="showResetPasswordModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        </form>
      </b-modal>
  
      <!-- Set DOB Modal -->
      <b-modal v-model="showSetDOBModal" title="Set Date of Birth">
        <form @submit.prevent="setDOB">
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input v-model="dobForm.dob" type="date" id="dob" class="form-control">
          </div>
          <b-button type="submit" variant="primary">Save</b-button>
          <b-button @click="showSetDOBModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        </form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { BModal } from 'bootstrap-vue';
  
  export default {
    components: {
      BModal,
    },
    data() {
      return {
        showModal: false,
        showResetPasswordModal: false,
        showSetDOBModal: false,
        newUser: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          role: 'Agent',
          managerId: '',
          dob: '',
          timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
        },
        passwordForm: {
          newPassword: '',
          confirmPassword: ''
        },
        passwordError: '',
        dobForm: {
          dob: ''
        },
        selectedRep: null,
        searchQuery: '',
        filterManager: '',
        salesReps: [],
        managers: [],
        inactiveAgents: [],
      };
    },
    computed: {
      filteredManagers() {
        if (this.filterManager) {
          const manager = this.managers.find(manager => manager.id === parseInt(this.filterManager));
          if (manager) {
            return [{
              ...manager,
              agents: this.salesReps.filter(rep => rep.managerId === manager.id && this.filterActiveReps(rep))
            }];
          }
          return [];
        }
        return this.managers.map(manager => ({
          ...manager,
          agents: this.salesReps.filter(rep => rep.managerId === manager.id && this.filterActiveReps(rep))
        }));
      }
    },
    methods: {
      getUserRoleFromToken() {
        // Get the JWT from local storage
        const token = localStorage.getItem("userToken");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Check if the 'cognito:groups' claim exists in the payload
        const groups = payload['cognito:groups'];

        if (!groups || groups.length === 0) {
          return null; // Return null if no groups are found
        }

        // Assuming the role is determined by the first group in the array
        return groups[0]; // Or you can adjust this to match your application's logic
      },
      getCognitoSub() {
        // Get the JWT from local storage
        const token = localStorage.getItem("user");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Return the 'sub' value from the payload
        return payload.sub || null;
      },
      async fetchSalesReps() {
        try {
          const response = await axios.get('https://123avatars.com/v2/sales-reps?managerId=' + this.getCognitoSub());
          this.salesReps = response.data.filter(rep => rep.role === 'Agent');
          this.managers = response.data.filter(rep => rep.role === 'Manager');
        } catch (error) {
          console.error('Error fetching sales reps:', error);
        }
      },
      async createUser() {
        try {
          let endpoint = 'https://123avatars.com/v2/';                                                                                  
          if (this.newUser.role === 'Manager') {
            endpoint += 'managers';
          } else {
            endpoint += 'sales-reps';
          }

          const response = await axios.post(endpoint, this.newUser);
          this.fetchSalesReps(); // Refresh the list after creating a new user
          this.showModal = false;
          this.newUser = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: 'Agent',
            managerId: '',
            dob: '',
            timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
          };
        } catch (error) {
          console.error('Error creating user:', error);
        }
      },
      async removeUser(id) {
        try {
          await axios.delete(`https://123avatars.com/v2/sales-reps/${id}`);
          this.fetchSalesReps(); // Refresh the list after deleting a user
        } catch (error) {
          console.error('Error removing user:', error);
        }
      },
      async removeManager(id) {
        try {
          // Send a DELETE request to remove the manager
          await axios.delete(`https://123avatars.com/v2/managers/${id}`);
          
          // Refresh the list of sales reps and managers after deleting the manager
          this.fetchSalesReps(); 
        } catch (error) {
          console.error('Error removing manager:', error);
        }
      },
      async resetPassword() {
        if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
          this.passwordError = 'Passwords do not match';
          return;
        }
        try {
          await axios.post(`https://123avatars.com/v2/sales-reps/${this.selectedRep.id}/reset-password`, {
            newPassword: this.passwordForm.newPassword,
          });
          this.showResetPasswordModal = false;
          this.passwordForm = {
            newPassword: '',
            confirmPassword: ''
          };
        } catch (error) {
          console.error('Error resetting password:', error);
        }
      },
      formatPhone(phone) {
        const cleaned = ('' + phone).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phone;
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
      calculateShelfLife(date) {
        const hireDate = new Date(date);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - hireDate);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      },
      calculateAge(dob) {
        if (!dob) return '';
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
      filterActiveReps(rep) {
        const query = this.searchQuery.toLowerCase();
        const fullName = `${rep.firstName} ${rep.lastName}`.toLowerCase();
        return fullName.includes(query) ||
          rep.email.toLowerCase().includes(query) ||
          rep.phone.includes(query);
      },
      openSetDOBModal(rep) {
        this.selectedRep = rep;
        this.dobForm.dob = rep.dob || '';
        this.showSetDOBModal = true;
      },
      async setDOB() {
        if (this.selectedRep) {
          try {
            // Update the selectedRep's dob locally
            this.selectedRep.dob = this.dobForm.dob;

            // Send the updated DOB to the backend
            await axios.put(`https://123avatars.com/v2/sales-reps/${this.selectedRep.id}/dob`, {
              dob: this.dobForm.dob
            });

            this.showSetDOBModal = false;
          } catch (error) {
            console.error('Error updating DOB:', error);
          }
        }
      }
    },
    mounted() {
      this.fetchSalesReps();
    }
  };
  </script>
  
  <style scoped>
  .card-header {
    background-color: #2c3e50;
  }
  </style>