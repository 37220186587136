<template>
    <div >
      <header>
        <h1>Call Backs Report</h1>
      </header>
      <main class="content">
        <div class="toggle-container">
          <button class="btn" @click="toggleCallBacks('future')">Show Future Call Backs</button>
          <button class="btn" @click="toggleCallBacks('previous')">Show Previous Call Backs</button>
        </div>
        <!-- <div class="filter-container">
          <label for="salesRepFilter">Sales Rep:</label>
          <select id="salesRepFilter" v-model="selectedRep" @change="filterBySalesRep">
            <option value="all">All</option>
            <option v-for="rep in salesReps" :key="rep" :value="rep">{{ rep }}</option>
          </select>
        </div> -->
        <div class="rounded-container">
          <h2>Report</h2>
          <div class="container-body" id="reportContainer" >
            <div v-for="group in filteredData" :key="group.date" class="date-group">
              <h3>{{ formatDate(group.date) }}</h3>
              <table class="call-backs-table">
                <thead>
                  <tr>
                    <th>Sales Rep</th>
                    <th>Call Back Time (User)</th>
                    <th>Call Back Time (Prospect)</th>
                    <th>Prospect Timezone</th>
                    <th>Company Name</th>
                    <th>Contact Name</th>
                    <th>Contact Phone</th>
                    <th>Set Up Ago</th>
                    <th>Total Talk Time</th>
                    <!-- <th>Sales Rep</th> -->
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in group.data" :key="row.contactPhone" :data-sales-rep="row.salesRep">
                    <td>{{ row.salesRep }}</td>
                    <td class="call-time">{{ formatLocalTimeByTimezone(row.callbackTime, null) }}</td>
                    <td class="call-time">{{ formatLocalTimeByTimezone(row.callbackTime, getTimezoneFromAbbreviation(row.timezone)) }}</td>
                    <td>{{ row.timezone }}</td>
                    <td>{{ row.company }}</td>
                    <td>{{ row.contactName }}</td>
                    <td><a :href="'tel:' + row.contactPhone">{{ formatPhoneNumber(row.contactPhone) }}</a></td>
                    <td>{{ row.setUpAgo }}</td>
                    <td>{{ row.talkTime }}</td>
                    <!-- <td>{{ row.salesRep }}</td> -->
                    <td class="action-buttons">
                      <button class="btn edit-btn" @click="openEditModal(row)">Edit</button>
                      <button class="btn delete-btn" @click="deleteRow(group.date, row.id)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
  
      <!-- The Modal -->
      <div v-if="showModal" class="modal" @click.self="closeModal">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Edit Callback</span>
            <span class="close" @click="closeModal">&times;</span>
          </div>
          <div class="modal-body">
            <div class="prospect-info">
              <p><strong>Owner's Name:</strong> <span>{{ editData.contact_name }}</span></p>
              <p><strong>Company Name:</strong> <span>{{ editData.company }}</span></p>
              <p><strong>Timezone:</strong> <span>{{ editData.timezone }}</span></p>
              <p><strong>Local Time:</strong> <span>{{ editData.timeUser }}</span></p>
              <div class="input-group">
                <label for="callbackTime">Callback Date and Time ({{ editData.timezone }})</label>
                <input type="datetime-local" v-model="editData.callbackTime">
              </div>
            </div>
            <div class="time-info">
              <p><strong>Your Time:</strong> <span>{{ userTime }}</span></p>
              <p><strong>Time Difference:</strong> <span>{{ timeDifference }}</span> hours</p>
            </div>
          </div>
          <div class="modal-footer">
            <button class="button close" @click="closeModal">Cancel</button>
            <button class="button" @click="saveCallbackChanges">Save Callback</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        salesReps: ['Rep A', 'Rep B', 'Rep C', 'Rep D', 'Rep E'],
        selectedRep: 'all',
        callBackData: [],
        showModal: false,
        editData: {},
        userTime: '',
        timeDifference: 0,
        userTimezone: ''
      };
    },
    computed: {
      filteredData() {
        if (this.selectedRep === 'all') {
          return this.callBackData;
        }
        return this.callBackData.map(group => ({
          ...group,
          data: group.data.filter(row => row.salesRep === this.selectedRep)
        }));
      }
    },
    methods: {
    formatLocalTime(dateString) {
        // Parse the date string to a Date object
        const date = new Date(dateString);

        // Options for formatting the time
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true // This will format the time in 12-hour format with AM/PM
        };

        // Format the time using the toLocaleTimeString method
        const localTimeString = date.toLocaleTimeString('en-US', options);

        return localTimeString;
        },
        formatLocalTimeByTimezone(dateString, timezone) {
        // Parse the date string to a Date object
        const date = new Date(dateString);
        
        if(!timezone) {
          timezone = this.getUserTimezone();
        }
        console.log('timezone: ', timezone);

        // Convert the date to the specified timezone
        const options = {
            timeZone: timezone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: true // This will format the time in 12-hour format with AM/PM
        };

        // Format the time using the toLocaleTimeString method with the specified timezone
        const localTimeString = date.toLocaleTimeString('en-US', options);

        return localTimeString;
    },
    getUserTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    getTimezoneFromAbbreviation(abbreviation) {
        const timezones = {
            'EST': 'America/New_York', // Eastern Standard Time
            'EDT': 'America/New_York', // Eastern Daylight Time
            'CST': 'America/Chicago',  // Central Standard Time
            'CDT': 'America/Chicago',  // Central Daylight Time
            'MST': 'America/Denver',   // Mountain Standard Time
            'MDT': 'America/Denver',   // Mountain Daylight Time
            'PST': 'America/Los_Angeles', // Pacific Standard Time
            'PDT': 'America/Los_Angeles', // Pacific Daylight Time
            'AKST': 'America/Anchorage', // Alaska Standard Time
            'AKDT': 'America/Anchorage', // Alaska Daylight Time
            'HST': 'Pacific/Honolulu',  // Hawaii Standard Time
            'HDT': 'Pacific/Honolulu'   // Hawaii Daylight Time (though Hawaii does not observe DST)
        };

        return timezones[abbreviation] || null;
    },
    getCognitoSub() {
        // Get the JWT from local storage
        const token = localStorage.getItem("user");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Return the 'sub' value from the payload
        return payload.sub || null;
      },
      getUserRoleFromToken() {
        // Get the JWT from local storage
        const token = localStorage.getItem("userToken");

        if (!token) {
          return null; // Return null if no token is found
        }

        // Decode the JWT (JWTs are Base64URL encoded)
        const payload = JSON.parse(atob(token.split('.')[1]));

        // Check if the 'cognito:groups' claim exists in the payload
        const groups = payload['cognito:groups'];

        if (!groups || groups.length === 0) {
          return null; // Return null if no groups are found
        }

        // Assuming the role is determined by the first group in the array
        return groups[0]; // Or you can adjust this to match your application's logic
      },
    formatPhoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.toString();
        if (phoneNumber.length > 10) {
            phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
        }
        const areaCode = phoneNumber.slice(0, 3);
        const centralOfficeCode = phoneNumber.slice(3, 6);
        const stationCode = phoneNumber.slice(6, 10);
        const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        return formattedPhoneNumber;
    },
      async loadInitialData() {
        let path = '';
        let role = this.getUserRoleFromToken();
        if(!role) {
          let id = this.getCognitoSub();
          path = '?salesRepId=' + id;
        }
        const response = await axios.get('https://123avatars.com/v2/callbacks' + path);
        this.callBackData = response.data;
      },
      formatDate(dateStr) {
        const date = new Date(dateStr);
        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
      },
      formatPhone(phone) {
        return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      },
      toggleCallBacks(type) {
        const futureGroups = document.querySelectorAll('.date-group.future');
        const previousGroups = document.querySelectorAll('.date-group.previous');
  
        if (type === 'future') {
          futureGroups.forEach(group => group.classList.remove('hidden'));
          previousGroups.forEach(group => group.classList.add('hidden'));
        } else {
          futureGroups.forEach(group => group.classList.add('hidden'));
          previousGroups.forEach(group => group.classList.remove('hidden'));
        }
      },
      filterBySalesRep() {
        // Logic handled by computed property
      },
      async deleteRow(date, id) {
        try {
            await axios.delete(`https://123avatars.com/v2/callbacks/${id}`);
            this.callBackData = this.callBackData.map(group => {
            if (group.date === date) {
                return {
                ...group,
                data: group.data.filter(row => row.id !== id)
                };
            }
            return group;
            });
        } catch (error) {
            console.error('Error deleting callback:', error);
        }
        },
      openEditModal(row) {
        this.editData = { ...row };
        this.showModal = true;
        this.updateUserTimeAndDifference();
      },
      closeModal() {
        this.showModal = false;
      },
      updateUserTimeAndDifference() {
        const selectedTime = new Date(this.editData.timeProspect);
        const userTimezoneOffset = selectedTime.getTimezoneOffset() / 60;
        const pstOffset = -8; // PST is UTC-8
  
        const userDate = new Date(selectedTime.getTime() + (userTimezoneOffset - pstOffset) * 60 * 60 * 1000);
        this.userTime = this.formatTime(userDate);
  
        const difference = Math.abs(userTimezoneOffset - pstOffset);
        this.timeDifference = difference;
      },
      formatTime(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`;
      },
      saveCallbackChanges() {
        const selectedTime = new Date(this.editData.timeProspect);
        const pstOffset = -8; // PST is UTC-8
  
        const userDate = new Date(selectedTime.getTime() + (selectedTime.getTimezoneOffset() / 60 - pstOffset) * 60 * 60 * 1000);
        this.editData.timeUser = this.formatTime(userDate);
  
        this.callBackData = this.callBackData.map(group => {
          if (group.date === this.editData.date) {
            return {
              ...group,
              data: group.data.map(row => (row.contactPhone === this.editData.contactPhone ? this.editData : row))
            };
          }
          return group;
        });
  
        this.closeModal();
      }
    },
    async mounted() {
        this.userTimezone = this.getUserTimezone();
        await this.loadInitialData();
    }
  };
  </script>
  
  <style scoped>
  body {
    background-color: #F4F8FF;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
  }
  header {
    background-color: #579aff;
    padding: 20px;
    color: #fff;
  }
  header h1 {
    margin-bottom: 0;
    color: #fff;
    font-weight: bold;
  }
  main .content {
    padding: 10px;
  }
  main .content .btn {
    background-color: #579AFF;
    color: #fff;
    margin: 10px;
    border: 2px solid #009aff;
    cursor: pointer;
  }
  main .content .btn:hover {
    background-color: #fff;
    color: #579AFF;
    border-color: #579AFF;
    margin: 10px;
  }
  main .rounded-container {
    margin: 10px;
    background-color: #fff;
    border-radius: 20px;
    background-clip: border-box;
  }
  main .rounded-container h2 {
    background-color: #C5DBFF;
    padding: 20px;
    font-size: 1.5rem;
    border-radius: 20px 20px 0 0;
  }
  main .rounded-container .container-body {
    padding: 10px 20px 20px;
  }
  .call-backs-table {
    width: 100%;
    border-collapse: collapse;
  }
  .call-backs-table th,
  .call-backs-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .call-backs-table th {
    background-color: #579aff;
    color: white;
  }
  .date-group {
    margin-top: 20px;
  }
  .date-group h3 {
    background-color: #C5DBFF;
    padding: 10px;
    border-radius: 10px;
  }
  .call-time {
    font-weight: bold;
  }
  .hidden {
    display: none;
  }
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  .action-buttons .btn {
    background-color: #579AFF;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  .action-buttons .btn:hover {
    background-color: #fff;
    color: #579AFF;
    border: 1px solid #579AFF;
  }
  .toggle-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .filter-container {
    margin-bottom: 20px;
  }
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  .modal-header, .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    background-color: #C5DBFF;
    border-radius: 20px 20px 0 0;
  }
  .modal-footer {
    border-top: 1px solid #e5e5e5;
    padding-top: 10px;
  }
  .modal-body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .modal-title {
    font-size: 1.5em;
    color: #333;
  }
  .close {
    color: #aaa;
    font-size: 1.5em;
    cursor: pointer;
  }
  .close:hover, .close:focus {
    color: #000;
  }
  .prospect-info {
    width: 45%;
  }
  .callback-info {
    width: 45%;
  }
  .input-group {
    margin-bottom: 15px;
  }
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  .input-group input, .input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .button {
    background-color: #579AFF;
    color: white;
    padding: 10px 20px;
    border: 2px solid #009aff;
    cursor: pointer;
    font-size: 1em;
  }
  .button:hover {
    background-color: #fff;
    color: #579AFF;
    border-color: #579AFF;
  }
  .time-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .container-body {
  max-height: 400px; /* Adjust this value based on your desired height */
  overflow-y: auto;
  padding-right: 10px; /* Optional: adds some space for the scrollbar */
}

.container-body::-webkit-scrollbar {
  width: 8px;
}

.container-body::-webkit-scrollbar-thumb {
  background-color: #579AFF; /* Customize the scrollbar color */
  border-radius: 10px;
}

.container-body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Customize the scrollbar track color */
}

  </style>