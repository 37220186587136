<template>
    <div class="container">
      <div class="main-content">
        <div class="header">Income Calculator</div>
  
        <div class="totals-container">
          <div>
            <div>Weekly Sales:</div>
            <div>{{ totalSalesCount }}</div>
          </div>
          <div>
            <div>Weekly Commission:</div>
            <div>${{ totalUpfront }}</div>
          </div>
          <div>
            <div>Monthly Commission:</div>
            <div>${{ monthlyRecurring }}</div>
          </div>
          <div>
            <div>Yearly Commission:</div>
            <div>${{ yearlyRecurring }}</div>
          </div>
        </div>
  
        <div class="calculator-container">
          <h2>Add New Sale</h2>
          <div class="compact-row">
            <div class="input-group">
              <label for="service">Service</label>
              <select v-model="selectedService" id="service" class="form-control">
                <option value="both">Both Services</option>
                <option value="180ads">180Ads Remarketing - $149/mo</option>
                <option value="123websites">123Websites - $99/mo</option>
              </select>
            </div>
            <div class="input-group">
              <label for="setup-fee-180ads">Setup Fee (180Ads)</label>
              <select v-model="selectedSetupFee180Ads" id="setup-fee-180ads" class="form-control">
                <option value="0">$0 Setup Fee</option>
                <option value="49">$49 Setup Fee</option>
                <option value="99">$99 Setup Fee</option>
                <option value="149">$149 Setup Fee</option>
              </select>
            </div>
            <div class="input-group">
              <label for="setup-fee-123websites">Setup Fee (123Websites)</label>
              <select v-model="selectedSetupFee123Websites" id="setup-fee-123websites" class="form-control">
                <option value="0">$0 Setup Fee</option>
                <option value="49">$49 Setup Fee</option>
                <option value="99">$99 Setup Fee</option>
              </select>
            </div>
            <button class="add-client-btn" @click="addClient">Add Client</button>
          </div>
        </div>
  
        <div class="income-summary">
          <h2>Income Summary</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Service</th>
                <th>Setup Fee</th>
                <th>First Month's Service</th>
                <th>Weekly Commission</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sale, index) in sales" :key="index">
                <td>{{ sale.service }}</td>
                <td>${{ sale.setupFee }}</td>
                <td>${{ sale.firstMonthService }}</td>
                <td>${{ sale.totalUpfront }}</td>
                <td><button class="remove-btn" @click="removeClient(index)">Remove</button></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">Total Income for the Week</td>
                <td class="total">${{ totalUpfront }}</td>
                <td></td>
              </tr>
              <tr v-if="bonusAmount > 0">
                <td colspan="3" class="bonus-text">{{ bonusText }}</td>
                <td class="total bonus-text">${{ bonusAmount }}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
  
        <div class="income-details">
          <h2>How Your Income Works</h2>
          <p>
            For each sale, you make <strong>100% of the upfront charge</strong>, which consists of the first month's payment and the setup fee. The setup fee can be as high as the first month's payment.
          </p>
          <ul>
            <li><strong>180Ads Remarketing:</strong> $149 for the first month + $149 setup fee = <strong>$298 total upfront</strong></li>
            <li><strong>123Websites:</strong> $99 for the first month + $99 setup fee = <strong>$198 total upfront</strong></li>
            <li><strong>Both Services:</strong> $149 + $99 for the first month + $149 + $99 setup fee = <strong>$496 total upfront</strong></li>
          </ul>
        </div>
  
        <div class="total-sales">
          Weekly Sales: {{ totalSalesCount }} | Total Weekly Income: ${{ totalUpfront }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedService: "both",
        selectedSetupFee180Ads: 149,
        selectedSetupFee123Websites: 99,
        sales: [
          { service: "Both Services", setupFee: 248, firstMonthService: 248, totalUpfront: 496 },
          { service: "180Ads Remarketing", setupFee: 149, firstMonthService: 149, totalUpfront: 298 },
          { service: "123Websites", setupFee: 99, firstMonthService: 99, totalUpfront: 198 },
          { service: "Both Services", setupFee: 248, firstMonthService: 248, totalUpfront: 496 },
          { service: "180Ads Remarketing", setupFee: 149, firstMonthService: 149, totalUpfront: 298 },
          { service: "123Websites", setupFee: 99, firstMonthService: 99, totalUpfront: 198 },
          { service: "Both Services", setupFee: 248, firstMonthService: 248, totalUpfront: 496 },
        ],
      };
    },
    computed: {
      totalSalesCount() {
        return this.sales.length;
      },
      totalUpfront() {
        let totalIncome = this.sales.reduce((sum, sale) => sum + sale.totalUpfront, 0);
        totalIncome += this.bonusAmount;
        return totalIncome;
      },
      monthlyRecurring() {
        return this.totalUpfront * 4;
      },
      yearlyRecurring() {
        return this.totalUpfront * 52;
      },
      bonusAmount() {
        if (this.totalSalesCount >= 10 && this.totalSalesCount < 15) {
          return 1000;
        } else if (this.totalSalesCount >= 15) {
          return 1500;
        }
        return 0;
      },
      bonusText() {
        if (this.totalSalesCount >= 10 && this.totalSalesCount < 15) {
          return "Bonus 10 Sales";
        } else if (this.totalSalesCount >= 15) {
          return "Bonus 15 Sales";
        }
        return "";
      },
    },
    methods: {
      addClient() {
        let serviceText = "";
        let setupFee = 0;
        let firstMonthService = 0;
        let totalUpfront = 0;
  
        if (this.selectedService === "180ads") {
          serviceText = "180Ads Remarketing";
          setupFee = this.selectedSetupFee180Ads;
          firstMonthService = 149;
          totalUpfront = 149 + setupFee;
        } else if (this.selectedService === "123websites") {
          serviceText = "123Websites";
          setupFee = this.selectedSetupFee123Websites;
          firstMonthService = 99;
          totalUpfront = 99 + setupFee;
        } else if (this.selectedService === "both") {
          serviceText = "Both Services";
          setupFee = this.selectedSetupFee180Ads + this.selectedSetupFee123Websites;
          firstMonthService = 149 + 99;
          totalUpfront = (149 + this.selectedSetupFee180Ads) + (99 + this.selectedSetupFee123Websites);
        }
  
        this.sales.push({
          service: serviceText,
          setupFee: setupFee,
          firstMonthService: firstMonthService,
          totalUpfront: totalUpfront,
        });
      },
      removeClient(index) {
        this.sales.splice(index, 1);
      },
    },
  };
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    background-color: #f5f8fa;
    color: #333;
    margin: 0;
    padding: 0;
  }
  .container {
    display: flex;
  }
  .sidebar {
    width: 220px;
    background-color: #394a7d;
    padding: 20px;
    height: 100vh;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  .sidebar a {
    color: white;
    text-decoration: none;
    display: block;
    margin: 15px 0;
  }
  .main-content {
    flex: 1;
    margin-left: 240px;
    padding: 20px;
  }
  .header {
    background-color: #87c0f5;
    padding: 15px;
    text-align: center;
    color: white;
    font-size: 20px;
    border-bottom: 2px solid #79abe3;
  }
  .totals-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #f5f8fa;
  }
  .totals-container div {
    text-align: center;
  }
  .calculator-container {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .calculator-container h2 {
    margin-top: 0;
    font-size: 18px;
  }
  .compact-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .input-group {
    flex: 1;
    margin-right: 10px;
  }
  .input-group:last-child {
    margin-right: 0;
  }
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 12px;
  }
  .input-group select,
  .input-group input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 12px;
  }
  .add-client-btn {
    background-color: #87c0f5;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    flex-shrink: 0;
  }
  .add-client-btn:hover {
    background-color: #79abe3;
  }
  .income-summary {
    margin-top: 20px;
  }
  .income-summary table {
    width: 100%;
    border-collapse: collapse;
  }
  .income-summary th,
  .income-summary td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  .income-summary th {
    background-color: #87c0f5;
    color: white;
  }
  .income-summary td.total {
    font-weight: bold;
  }
  .remove-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .remove-btn:hover {
    background-color: #d32f2f;
  }
  .bonus-text {
    color: #007bff; /* Blue color */
    font-weight: bold;
  }
  .total-sales {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  .income-details {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .income-details h2 {
    margin-top: 0;
  }
  .income-details ul {
    list-style-type: none;
    padding: 0;
  }
  .income-details ul li {
    margin: 10px 0;
  }
  </style>