<template style="width:100%">
  <div>
    <Breadcrumbs main="Project" title="IVR" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="container my-4">
        <!-- <h1 class="display-4 mb-4">Transferring Inbound Calls Page</h1> -->
        <br>
        <br>
        <br>
        <br>
        <br>

        <!-- Call Forwarding Section -->
        <div>
          <h2>Call Forwarding</h2>
          <div class="row mb-4">
            <div class="col-md-3">
              <input type="text" placeholder="Phone Number to Forward to" v-model="newForwarding.phoneNumber" class="form-control">
            </div>
            <div class="col-md-3">
              <input type="text" placeholder="Department" v-model="newForwarding.department" class="form-control">
            </div>
            <div class="col-md-12 mt-3">
              <button class="btn btn-success w-100" @click="addNew">Add New</button>
            </div>
          </div>
          <div v-for="(entry, index) in entries" :key="index" class="row mb-2">
            <div class="col-md-2">
              <input type="text" v-model="entry.message" class="form-control">
            </div>
            <div class="col-md-2">
              <input type="text" v-model="entry.department" class="form-control">
            </div>
            <div class="col-md-2">
              <input type="text" v-model="entry.phoneNumber" class="form-control">
            </div>
            <div class="col-md-2">
              <button class="btn btn-danger w-100" @click="deleteEntry(index)">Delete</button>
            </div>
          </div>
          <button class="btn btn-primary mt-4" @click="saveEntries">Save Changes</button>
        </div>
        <br>
        <!-- Call Transfers Section -->
        <div>
          <h2>Call Transfers</h2>
          <div class="row">
            <div class="col-md-3">
              <label>Support Number</label>
            </div>
            <div class="col-md-3">
              <input type="text" placeholder="Caller ID Forwarding Phone Number" v-model="supportNumber" class="form-control">
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary w-100" @click="saveEntries">Save New Phone Number</button>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-3">
              <label>Rep Shared Number</label>
            </div>
            <div class="col-md-3">
              <input type="text" placeholder="Caller ID Forwarding Phone Number" v-model="repSharedNumber" class="form-control">
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary w-100" @click="saveEntries">Save New Phone Number</button>
            </div>
          </div>
          <br>
          <div class="row mb-4">
            <div class="col-md-3">
              <label>Caller ID Forwarding Number</label>
            </div>
            <div class="col-md-3">
              <input type="text" placeholder="Caller ID Forwarding Phone Number" v-model="callerIdPhoneNumber" class="form-control">
            </div>
            <div class="col-md-1">
              <label>
                <input type="checkbox" v-model="selectedCallerId"> Forward
              </label>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary w-100" @click="saveEntries">Save New Phone Number</button>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-3">
              <label>Voicemail Forwarding Number</label>
            </div>
            <div class="col-md-3">
              <input type="text" placeholder="Voicemail Forwarding Phone Number" v-model="voicemailPhoneNumber" class="form-control">
            </div>
            <div class="col-md-1">
              <label>
                <input type="checkbox" v-model="selectedVoicemail"> Forward
              </label>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary w-100" @click="saveEntries">Save New Phone Number</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newForwarding: {
        phoneNumber: '',
        department: ''
      },
      entries: [],
      callerIdPhoneNumber: '',
      voicemailPhoneNumber: '',
      selectedCallerId: false,
      selectedVoicemail: false,
      repSharedNumber: '',
      supportNumber: ''
    };
  },
  methods: {
    fetchEntries() {
      axios.get('https://123avatars.com/v2/ivr?userId=' + localStorage.getItem('userId'))
        .then(response => {
          if (response.data) {
            this.entries = JSON.parse(response.data.entries);
            this.callerIdPhoneNumber = response.data.caller_id_phone_number;
            this.voicemailPhoneNumber = response.data.voicemail_phone_number;
            this.selectedCallerId = response.data.caller_id_is_forwarding;
            this.selectedVoicemail = response.data.voicemail_is_forwarding;
            this.repSharedNumber = response.data.rep_shared_number;
            this.supportNumber = response.data.support_number;
          } else {
            this.entries = [];
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    addNew() {
      this.entries.push({
        message: `I’d like to speak to someone in the ${this.newForwarding.department} department`,
        department: this.newForwarding.department,
        phoneNumber: this.newForwarding.phoneNumber
      });
      this.newForwarding.phoneNumber = '';
      this.newForwarding.department = '';
    },
    deleteEntry(index) {
      this.entries.splice(index, 1);
    },
    saveEntries() {
      let data = {
        userId: localStorage.getItem('userId'),
        entries: this.entries,
        caller_id_phone_number: this.callerIdPhoneNumber,
        voicemail_phone_number: this.voicemailPhoneNumber,
        caller_id_is_forwarding: this.selectedCallerId,
        voicemail_is_forwarding: this.selectedVoicemail,
        repSharedNumber: this.repSharedNumber,
        supportNumber: this.supportNumber
      };
      axios.post('https://123avatars.com/v2/ivr?userId=' + localStorage.getItem('userId'), data)
        .then(response => {
          console.log('Data saved successfully');
          this.$toasted.show("IVRs saved successfully.", {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "info",
            duration: 10000,
          });
        })
        .catch(error => {
          console.error('Error saving data:', error);
          this.$toasted.show("Failed to save IVR.", {
            theme: "outline",
            position: "top-right",
            type: "danger",
            icon: "info",
            duration: 10000,
          });
        });
    }
  },
  mounted() {
    this.fetchEntries();
  }
};
</script>

<style>
/* Add any additional styles if needed */
</style>