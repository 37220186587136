import {
  CognitoUserPool,
  CognitoUser,
} from "amazon-cognito-identity-js";

// Configuration for your Cognito User Pool
const poolData = {
  UserPoolId: "us-west-1_Xb5sM57hy", // Replace with your actual user pool id
  ClientId: "459g3alqohqvrvrln2ccla7r0v", // Replace with your actual client id
};

// Initialize the Cognito User Pool
const userPool = new CognitoUserPool(poolData);

class CognitoService {
  getCognitoSub() {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        // Get the session, which includes the ID token
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error retrieving session:', err);
            return reject(null);
          }

          if (session && session.isValid()) {
            // Decode the JWT token and extract the payload
            const payload = session.getIdToken().payload;

            // Resolve the 'sub' value from the payload
            resolve(payload.sub || null);
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }
}

export default new CognitoService();
