<template>
  <div class="chat-container">
    <div class="chat-header">
      <h2>{{ headerTitle }}</h2>
    </div>
    <div class="chat-messages" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" :class="['message', getMessageClass(message)]">
        <span class="sender">{{ getMessageSender(message) }}</span>
        <span>{{ message.text }} - {{ message.time }}</span>
      </div>
    </div>
    <div class="chat-input">
      <input type="text" v-model="messageInput" placeholder="Type your message..." @keyup.enter="sendMessage" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';
import SocketService from './socketService.js';

export default {
  props: {
    type: String,
    salesRepId: String,
    managerId: String,
    headerTitle: {
      type: String,
      default: 'Chat'
    }
  },
  data() {
    return {
      messageInput: '',
      messages: [],
      socket: null,
      userId: this.$route.query.id,
      userType: this.type // either 'REP' or 'MANAGER'
    };
  },
  mounted() {
    this.socket = io('https://123avatars.com/v2');

    console.log("userType: ", this.userType);
    if (this.userType === 'REP') {
      let resp = this.socket.emit('register', this.salesRepId);
      console.log("resp: ", resp);
    } else if (this.userType === 'MANAGER') {
      this.socket.emit('register', this.managerId);
    }


    this.socket.on('new_message', (message) => {
      console.log("mesageL: ", message);
      this.messages.push(message);
      this.$nextTick(this.scrollToBottom);
    });

    this.fetchMessages();
  },
  methods: {
    fetchMessages() {
      fetch(`https://123avatars.com/v2/get-messages/${this.salesRepId}/${this.managerId}`)
        .then(response => response.json())
        .then(data => {
          this.messages = data;
          this.$nextTick(this.scrollToBottom);
        });
    },
    sendMessage() {
      if (this.messageInput.trim() !== '') {
        const senderId = this.userType === 'REP' ? this.salesRepId : this.managerId;
        const message = {
          sales_rep_id: this.salesRepId,
          manager_id: this.managerId,
          sender: senderId,
          text: this.messageInput,
        };

        fetch('https://123avatars.com/v2/send-message', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(message),
        })
          .then(response => response.json())
          .then(() => {
            this.messageInput = '';
            this.$nextTick(this.scrollToBottom);
          });
      }
    },
    scrollToBottom() {
      const chatMessages = this.$refs.chatMessages;
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
    getMessageClass(message) {
      const senderId = this.userType === 'REP' ? this.salesRepId : this.managerId;
      return message.sender === senderId ? 'message-user' : 'message-manager';
    },
    getMessageSender(message) {
      if (this.userType === 'REP') {
        return message.sender === this.salesRepId ? 'From: Me' : 'From: Manager';
      } else if (this.userType === 'MANAGER') {
        return message.sender === this.managerId ? 'From: Me' : 'From: Sales Rep';
      }
    }
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.chat-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  padding: 20px;
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  position: relative;
}

.message-manager {
  background-color: #007bff;
  color: #fff;
  align-self: flex-start;
}

.message-user {
  background-color: #e0e0e0;
  align-self: flex-end;
}

.message .sender {
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.message.missed {
  border: 2px solid #ff0000;
  background-color: #ffcccc;
}

.chat-input {
  display: flex;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
</style>